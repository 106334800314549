<template>
  <span>
    <ba-dialog
      ref="d"
      small
      btnName="Edit"
      :show="dialog"
      v-on:show-dialog="dialog = true"
      title="Edit External Tax Details"
    >
      <!--<v-container grid-list-md v-if="dialog">-->
      <!--      <v-flex xs12 v-if="isLoading > 0">-->
      <!--          <v-progress-linear-->
      <!--                  indeterminate-->
      <!--                  color="green"-->
      <!--                  :active="isLoading > 0"-->
      <!--          ></v-progress-linear>-->
      <!--      </v-flex>-->
      <v-layout row wrap>
        <v-flex xs6 v-if="ad && ad.direction === 'Incoming' && acquisitionDetails">
          <v-form ref="formAcquisition" v-model="valid">
            <v-text-field
              v-model="acquisitionDetails.acquisitionCostInBaseCurrency"
              label="Acquisition Cost"
              :rules="[rules.number, rules.required]"
              required
            ></v-text-field>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field
                slot="activator"
                label="Acquisition Date"
                v-model="acquisitionDetails.acquisitionDate"
                :rules="[rules.required, rules.isDate]"
                required
                readonly
              ></v-text-field>
              <v-date-picker v-model="acquisitionDetails.acquisitionDate" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-form>
        </v-flex>
        <v-flex xs6 v-if="ad && ad.direction === 'Outgoing' && dispositionDetails">
          <v-form ref="formDisposition" v-model="valid">
            <v-text-field
              v-model="dispositionProceedsInBaseCurrency"
              label="Disposition Proceeds"
              :rules="[rules.number, rules.required]"
            ></v-text-field>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <!-- https://codepen.io/kzelda/pen/pWOLBp -->
              <v-text-field
                slot="activator"
                label="Disposition Date"
                v-model="dispositionDate"
                :rules="[rules.required, rules.isDate]"
                readonly
              ></v-text-field>
              <v-date-picker v-model="dispositionDate" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-form>
        </v-flex>
        <v-flex xs5 offset-xs1>
          <table>
            <tr>
              <td colspan="3">
                <h3>Transaction Details</h3>
              </td>
            </tr>
            <tr>
              <td>Transcation Type:</td>
              <td></td>
              <td class="data">{{ ad.direction }} transfer</td>
            </tr>
            <tr>
              <td>Transfer Date:</td>
              <td></td>
              <td class="data">{{ toOrgLocalTime(txn.created) }}</td>
            </tr>
            <tr>
              <td>Transfer Amount:</td>
              <td></td>
              <td class="data">
                <ul>
                  <li v-for="(item, index) in txn.amounts" v-bind:key="'a-' + index">
                    {{ item.coin }}
                    {{ item.displayValue }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Exchange Rates</td>
              <td></td>
              <td class="data">
                <ul>
                  <li v-for="(item, index) in ad.exchangeRates" v-bind:key="'a-' + index">
                    {{ item.coin }}
                    {{ item.rate }}
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </v-flex>
        <v-flex xs6 offset-xs4>
          <v-btn @click="save" :disabled="!valid" :loading="isSaving">Save</v-btn>
        </v-flex>
      </v-layout>
    </ba-dialog>
  </span>
</template>

<script>
import gql from 'graphql-tag';

export default {
  props: ['txn', 'refresh'],
  components: {},
  data() {
    return {
      valid: false,
      rules: {
        number: (value) => !isNaN(Number(value)) || 'Invalid Number',
        required: (v) => !!v || 'Field is required',
        isDate: (value) => this.parseDate(value) || 'Invalid Date',
      },
      isSaving: false,
      dialog: false,
      acquisitionDetails: {
        acquisitionCostInBaseCurrency: null,
        acquisitionDate: null,
      },
      dispositionDetails: {
        dispositionProceedsInBaseCurrency: null,
        dispositionDate: null,
      },
      dispositionProceedsInBaseCurrency: null,
      dispositionDate: null,
      errors: null,
    };
  },
  mounted() {
    this.populateForm();
  },
  methods: {
    populateForm() {
      if (this.ad.dispositionDetails) {
        const { dispositionProceedsInBaseCurrency, dispositionDate } = this.ad.dispositionDetails;
        this.dispositionDetails.dispositionProceedsInBaseCurrency = dispositionProceedsInBaseCurrency;
        this.dispositionDetails.dispositionDate = dispositionDate;
        this.dispositionProceedsInBaseCurrency = dispositionProceedsInBaseCurrency;
        this.dispositionDate = dispositionDate;
      } else if (this.ad.acquisitionDetails) {
        this.acquisitionDetails.acquisitionCostInBaseCurrency =
          this.ad.acquisitionDetails.acquisitionCostInBaseCurrency;
        this.acquisitionDetails.acquisitionDate = this.ad.acquisitionDetails.acquisitionDate;
      }
    },
    validate() {
      if (this.ad.direction === 'Incoming') {
        this.$refs.formAcquisition.validate();
      } else if (this.ad.direction === 'Outgoing') {
        this.$refs.formDisposition.validate();
      }
    },
    async save() {
      this.validate();
      if (!this.valid) return;

      this.isSaving = true;

      const vars = {
        orgId: this.$store.state.currentOrg.id,
        id: this.txn.id,
      };
      this.dispositionDetails.dispositionProceedsInBaseCurrency = this.dispositionProceedsInBaseCurrency;
      this.dispositionDetails.dispositionDate = this.dispositionDate;
      this.ad.dispositionDetails = {
        dispositionProceedsInBaseCurrency: this.dispositionProceedsInBaseCurrency,
        dispositionDate: this.dispositionDate,
      };
      if (this.ad.direction === 'Outgoing') {
        vars.externalTaxDetails = {
          dispositionDetails: this.dispositionDetails,
        };
      } else if (this.ad.direction === 'Incoming') {
        vars.externalTaxDetails = {
          acquisitionDetails: this.acquisitionDetails,
        };
      } else {
        this.errors = 'Incorrect transaction setup';
      }
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!, $externalTaxDetails: ExternalTaxDetailsInput!) {
            updateExternalTaxDetails(orgId: $orgId, id: $id, externalTaxDetails: $externalTaxDetails)
          }
        `,
        variables: vars,
      });

      if (this.ad.acquisitionDetails) {
        this.acquisitionDetails.acquisitionCostInBaseCurrency = null;
        this.acquisitionDetails.acquisitionDate = null;
      } else if (this.ad.acquisitionDetails) {
        this.dispositionDetails.dispositionProceedsInBaseCurrency = null;
        this.acquisitionDetails.dispositionDate = null;
      }

      await this.refresh();
      await this.populateForm();
      this.dialog = false;
      this.isSaving = false;
    },
  },
  computed: {
    ad() {
      return this.txn.accountingDetails[0].accountTransfer;
    },
    canSave() {
      if (this.transactionData && this.transactionData.valid === true) {
        return true;
      }
      return false;
    },
    readonly() {
      if (this.transaction) {
        return this.transaction.readonly;
      } else {
        return false;
      }
    },
  },
  watch: {
    // async dialog() {
    //   await this.$apollo.queries.transaction.refetch();
    //   this.populateForm();
    // },
  },
};
</script>

<style>
td.data {
  font-weight: bold;
}
</style>
