















import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import ExternalCostBasis from '../../components/accounting/ExternalCostBasis.vue';
@Component({
  components: {
    ExternalCostBasis,
  },
})
export default class ExternalCostBasisClass extends BaseVue {
  public selectedTab = 0;
}
