<!-- https://front.id/en/articles/vuetify-achieve-multiple-filtering-data-table-component -->
<template>
  <v-layout row wrap align-center justify-end class="pt-1">
    <v-flex xs12 class="pa-1">
      <div class="text-xs-center pt-2">
        <pagination
          :query="q"
          :query-vars="vars"
          :page-pivot-fn="(item) => item.created"
          v-on:items="renderDataTable"
          :total-fn="(data) => data.transactions.txns.length"
          :items-fn="(data) => data.transactions.txns"
          ref="pagination"
          v-on:isLoading="isPaginationLoadingHandler"
        />
      </div>
    </v-flex>
    <v-flex xs12 class="pa-1">
      <v-checkbox v-model="taxDetailsSet" label="Tax Details Set" />
    </v-flex>
    <v-flex xs12 class="pa-1">
      <v-data-table
        :headers="headers"
        :items="transactions.txns"
        item-key="id"
        :loading="isLoadingAll ? 'accent' : false"
        class="elevation-3"
        hide-actions
        select-all
        v-model="selected"
        ref="txnTable"
      >
        <template slot="items" slot-scope="tx">
          <tr :active="tx.selected">
            <!--<td>{{tx.item.amounts}}</td>-->
            <td>
              <v-checkbox primary hide-details v-model="tx.selected"></v-checkbox>
            </td>
            <td class="text-xs-left short-id">
              <blockchain-explorer-link
                :id="tx.item.id"
                :amounts="tx.item.amounts"
                :is-blockchain="tx.item.isBlockchain"
              />
            </td>
            <td class="text-xs-left" style="white-space: nowrap">
              {{ toPreferredDateTime(tx.item.created) }}
            </td>
            <td class="text-xs-left">
              <div class="text-xs-left">
                <ul style="list-style: none" class="pa-0">
                  <li v-for="item in tx.item.wallet" v-bind:key="item.name">
                    {{ item.name }}
                  </li>
                </ul>
                <!--{{ tx.item.wallet.name }}-->
              </div>
            </td>
            <td>
              <v-layout
                row
                v-for="(item, index) in tx.item.amounts"
                v-bind:key="`f-${index}`"
                :style="{ color: item.value < 0 ? 'red' : 'green' }"
              >
                <v-flex xs4>{{ getCurrencySymbol(item.coin) }}</v-flex>
                <v-flex xs8>{{ item.displayValue }}</v-flex>
              </v-layout>
            </td>
            <td>
              <edit-external-tax-details
                :txn="tx.item"
                :refresh="refresh"
                v-if="checkScope(scopeLiterals.CostBasisUpdate) && isPaginationLoading <= 0"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar :color="snackbarColor" v-model="snackbar" :timeout="10000" :top="true" multi-line vertical>
      {{ snackbarText }}
      <ul v-if="snackbarErrors.length">
        <li v-for="item in snackbarErrors" v-bind:key="item">{{ item }}</li>
      </ul>
      <v-btn flat @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </v-layout>
</template>

<script>
import gql from 'graphql-tag';
import moment from 'moment';

import { ExternalCostBasisQuery } from '../../queries/transactionsPageQuery';
import Pagination from '../Pagination.vue';
import BlockchainExplorerLink from '../transactions/BlockchainExplorerLink';
import EditExternalTaxDetails from './EditExternalTaxDetails';

export default {
  props: [],
  components: {
    EditExternalTaxDetails,
    Pagination,
    BlockchainExplorerLink,
  },
  data() {
    return {
      q: ExternalCostBasisQuery,
      snackbar: false,
      snackbarColor: 'success',
      snackbarText: '',
      snackbarErrors: [],
      taxDetailsSet: false,
      headers: [
        {
          text: this.$t('_id'),
          align: 'left',
          value: 'id',
        },
        {
          text: this.$t('_date'),
          align: 'left',
          value: 'created',
        },
        {
          text: this.$tc('_wallet', 2),
          align: 'left',
          value: 'wallet',
        },
        {
          text: this.$t('_amount'),
          align: 'left',
          value: 'amount.value',
        },
        {
          text: this.$tc('_action', 2),
          align: 'center',
          value: '',
        },
      ],
      transactions: { txns: [], count: 0 },
      isLoading: 0,
      categories: [],
      contacts: [],
      selected: [],
      isPaginationLoading: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    isPaginationLoadingHandler: function (isLoading) {
      this.isPaginationLoading = isLoading;
    },
    saveExternalCost() {
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        transactionIds: this.selected.map((m) => m.id),
      };
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation ($orgId: ID!, $transactionIds: [String]!) {
              reconcileTransactions(orgId: $orgId, transactionIds: $transactionIds) {
                connectionId
                succeeded
                errors
              }
            }
          `,
          // Parameters
          variables: vars,
        })
        .then((res) => {
          // if (res.errors || res.data.reconcileTransactions[0].errors) {
          const erroredTxns = res.data.reconcileTransactions.filter((m) => {
            return m.errors && m.errors.length > 0;
          });

          if ((res.errors && res.errors.length > 0) || erroredTxns.length > 0) {
            const errors = [];
            if (res.errors && res.errors.length > 0) {
              errors.push(...res.errors);
            }

            if (erroredTxns && erroredTxns.length > 0) {
              for (const e of erroredTxns) {
                errors.push(...e.errors);
              }
            }

            this.snackbarText =
              this.$tc('_reconciliationFailure', this.selectedCount) + ': ' + JSON.stringify(errors.join('<br />'));
            this.snackbarErrors = res.data.reconcileTransactions[0].errors;
            this.snackbarColor = 'error';
            this.snackbar = true;
          } else if (res.data.reconcileTransactions[0].succeeded) {
            this.snackbarText = this.$tc('_reconciliationSuccess', this.selectedCount);
            this.snackbarErrors = [];
            this.snackbarColor = 'success';
            this.snackbar = true;
          }

          this.selected = [];
          this.refresh();
        });
    },
    toggleAll() {
      const anySelected = this.transactions.txns.find((m) => m.selected);
      if (anySelected) {
        this.transactions.txns.forEach((m) => {
          this.$set(m, 'selected', false);
          // m.selected = false;
        });
      } else {
        this.transactions.txns.forEach((m) => {
          this.$set(m, 'selected', true);
        });
      }
    },
    async refresh() {
      this.$refs.pagination.refetch();
    },
    renderDataTable: function (data) {
      this.transactions = data.transactions;
    },
    getCurrencySymbol(coin) {
      if (coin === 'BTC') {
        return '฿';
      } else if (coin === 'ETH') {
        return 'Ξ';
      } else if (coin === 'EOS') {
        return 'EOS';
      } else {
        return coin;
      }
    },
    // toLocalDateTime(timestamp) {
    //   return moment.unix(timestamp).local().format('lll');
    //   // return moment(timestamp, "X")
    //   //   .utc()
    //   //   .format("lll");
    // },
  },
  computed: {
    isLoadingAll: function () {
      return this.isLoading + this.isPaginationLoading > 0;
    },
    vars() {
      return {
        orgId: this.$store.state.currentOrg.id,
        transactionFilter: {
          accountingDetails: {
            type: 'AccountTransfer',
            taxable: true,
            taxDetailsMissing: !this.taxDetailsSet,
          },
        },
      };
    },
    selectedCount() {
      if (this.selected && this.selected.length) {
        return this.selected.length;
      } else {
        return 0;
      }
    },
  },
};
</script>
